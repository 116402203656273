// TODO: #44 (https://github.com/vib-gmbh/vib-connect-flix/issues/44)
import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

import Logo from '../logo';
import Login from '../login';
import { useState } from 'preact/hooks';
import { useCallback, useEffect } from 'preact/compat';
import axios from 'axios';

import config from '../../config';

const Header = () => {
  const [data, dataSet] = useState(null);

  const fetchData = useCallback(async () => {
    // const response = await axios.get(`http://localhost:3000/drive/list`);
    const response = await axios.get(`${config.apibaseuri}/drive/list`, {
      withCredentials: true,
    });
    dataSet(response.data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <header className={style.header}>
      <Logo />
      <nav className={style.nav} role={'navigation'}>
        <div id={'menuToggle'}>
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id={'menu'}>
            {data &&
              data.nav &&
              data.nav.map((item, index) => {
                return (
                  <li key={`content-nav-${index}`}>
                    <Link activeClassName={style.active} href={item.url}>
                      {item.text}
                    </Link>
                  </li>
                );
              })}
            <li style="padding:0;">
              <Login />
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
