import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import axios from 'axios';

import config from '../../config';
import style from './style.css';

const Login = (props) => {
  const [status, statusSet] = useState(null);

  const getStatus = useCallback(async () => {
    const response = await axios.get(`${config.apibaseuri}/status`, {
      withCredentials: true,
    });
    statusSet(response.data);
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const href = status && !status.authorized ? 'login' : 'logout';
  return (
    <Link className={`${style.master} ${style[href]}`} href={`/${href}`}>
      {href}
    </Link>
  );
};

export default Login;
