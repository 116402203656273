const config = {
  colorcode: '#d72622' || '#72f887',
  colordark: '#000000' || '#263d42',
  colorlight: '#ffffff' || '#d7dadb',
  company: 'STRABAG BMTI Rail Service GmbH' || 'vib GmbH',
  logoURI:
    'https://utils.vib-development.ch/strabag/BMTIRailService/logo.jpg' || 'https://utils.vib-development.ch/vib/logo.svg',
  address: 'STRABAG BMTI Rail Service GmbH,Bessemerstr. 42b,12103 Berlin/Deutschland'?.split(',') || [
    'vib GmbH',
    'Grabenstrasse 12',
    '6340 Baar',
  ],
  contact: '+49 30 670 69 09-62,berlin@strabag-rail.com'?.split(',') || [
    '+41 (0)41 763 16 94',
    'support@vib-gmbh.ch',
  ],
  apibaseuri:
    'https://api.strabag-manual.vib-connect.de' ||
    'https://api.main.vib-connect-manual.ch4.amazee.io',
  appname: 'STRABAG BMTI Rail Service ECM Manual' || 'vib connect Manual',
  appdomain: 'strabag-manual.vib-connect.de' || 'manual.vib-gmbh.ch',
  appurl: 'https://strabag-manual.vib-connect.de' || 'https://manual.vib-gmbh.ch',
  statuspage: 'https://status.vib-development.ch' || 'https://status.vib-development.ch',
  support: 'support@vib-gmbh.ch' || 'support@vib-gmbh.ch',
};

export default config;
