import { h } from 'preact';
import { Route, Router } from 'preact-router';

import NotFoundPage from '../routes/notfound';
import Header from './header';
import Auth from '../routes/auth';
import Page from '../routes/page';
import Docs from '../routes/docs';

import Stroke from './stroke';

const App = () => {
  return (
    <div id="preact_root">
      <Stroke />
      <Header />
      <Router>
        <Route path="/" component={Docs} />
        <Route path="/login" component={Auth} />
        <Route path="/logout" component={Auth} />
        <Route path={'/p/:title'} component={Page} />
        <Route path={'/d/:tid'} component={Docs} />
        <NotFoundPage default />
      </Router>
    </div>
  );
};

export default App;
